<template>
    <section>
        <p>
          WATER GATE FLOW TECHNOLOGIES dosing pumps can offer different types of
          dosing pump systems according to clients' requirements.
        </p>
        <p>
          Reasonable and flexible internal design makes customization
          realizable. We can provide solutions for you according to user demand
          and special working conditions to avoid the embarrassing modification
          of process design upon the unsuitability of the product. We stick to
          the principle of providing clients with more professional and quicker
          support all the time. Through the experience and accumulation in the
          past years, we have comprehended both dosing pumps and the water
          treatment industry more profoundly. Our strong technical team can
          provide individualized products or compact electronic dosing systems
          centering on a dosing pump for your production process according to
          your particular requirements.
        </p>
        <h2>CUSTOMIZABLE SKIDS AND DOSING CABINS</h2>
        <p>
          WATER GATE FLOW TECHNOLOGIES dosing pumps can offer different types of
          dosing pump systems according to clients' requirements.
        </p>
        <p>
          Assembled solutions include electric control panels, designed and
          tested to operate the instruments.
        </p>

        <div class="image-card">
          <img src="~@/assets/images/SACDS.png" alt="" />
          <p class="caption">4-20mA Signal Automatic Control Dosing System</p>
        </div>
        <div class="image-card">
          <img src="~@/assets/images/CWSE.png" alt="" />
          <p class="caption">Cooling Water Smog Equipment</p>
        </div>
        <div class="image-card">
          <img
            src="~@/assets/images/cooling-water-smog-equipment-2.png"
            alt=""
          />
          <p class="caption">Cooling Water Smog Equipment</p>
        </div>
        <div class="image-card">
          <img
            src="~@/assets/images/cooling-water-smog-equipment-3.png"
            alt=""
          />
          <p class="caption">Cooling Water Smog Equipment</p>
        </div>
    </section>
</template>
<script>
export default {
  title: "Dosing Pumps",
}
</script>